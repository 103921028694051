<template>
  <div class="container-fluid padding-top-layout padding-right-layout padding-left-layout">
    <div class="list-data-header">
      <page-title :title="titlePage" />
      <common-button
        class="submit-register-btn"
        :label="$t('supplier.button_send_data')"
        :disabled="false"
        type="colored"
        @action="onHandleActionTable()"
        v-ripple="false"
        id="submit-register-btn"
      />
    </div>
    <p class="list-menu--title">{{ $t('supplier_products.description_register_emission_table') }}</p>
    <div class="wrap new-register-supplier">
      <div
        class="category-table table-supplier custom-table"
        :class="[isFullScreen && 'full-screen', isExpand ? 'expanded' : 'no-expanded']"
      >
        <data-table
          :data-source="supplierGrid"
          :grid-columns="gridColumns"
          :init-grid="initializeGrid"
          :rowFocus="1"
          :showFocus="false"
          :isShowToolbar="false"
          :isResizeCustome="false"
          @changeFullScreen="onChangeFullScreen"
          :isEmptySource="isEmptySource"
          :labelEmpty="messageLabelEmpty"
          :allowRightClick="true"
          :allowAddNew="false"
        />
      </div>
    </div>
    <dialog-popup
      :dialog="dialogPopup"
      :message="$t('supplier.description_comfirm_send_data')"
      @submit="() => submitDataSupplier()"
      @close="dialogPopup = false"
      :loading="isLoadingSubmit"
    />
    <EvidenceStoragePopup
      :dialog.sync="openDialogEvidenceStorage"
      :typeForm="typeFormEvidenceStorage"
      :descriptionScope="descriptionScope"
      :evidences="evidences"
      @onSubmitSuccess="handleSubmitAttachFile"
      @onDelete="handleDeleteAttachFile"
      :isShowDetailValue="false"
    ></EvidenceStoragePopup>
    <notification-popup :dialog="alertNotify" :message="messageNotification" @submit="alertNotify = false" />
    <notification-popup
      :dialog="dialogDeleteDataApproval"
      :message="messageDialogDeleteDataApproval"
      @submit="dialogDeleteDataApproval = false"
    />
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { CollectionView } from '@mescius/wijmo';
import _ from 'lodash';
import DataTable from '@/components/category/data-table';
import { formatDateTime } from '@/utils/datetimeFormat';
import { ROUTES } from '@/router/constants';
import { KEYS_CODE } from '@/constants/keyboard';
import * as wjGrid from '@mescius/wijmo.grid';
import * as wjcCore from '@mescius/wijmo';
import PageTitle from '@/components/pageTitle/pageTitle';
import { CellMaker } from '@mescius/wijmo.grid.cellmaker';
import { BLANK_ID } from '@/constants/registerData';
import { emptyPromise } from '@/concerns/registerData/wijmo.helper';
import DialogPopup from '@/components/dialogs/question-popup';
import NotificationPopup from '@/components/dialogs/notification-popup.vue';
import { getColumnIndexByBinding } from '@/concerns/utils/filter-data';
import CommonButton from '@/components/utils/button.vue';
import { addThoundSandComma } from '@/utils/convertNumber';
import EvidenceStoragePopup from '@/views/supplier/EvidenceStorage/index.vue';
import { submitDataSupplierCFPAction, infoFormPrimaryDataApi, getDetailUnitSupplier } from '@/api/supplier';
import { getErrorMessge } from '@/utils/messageHandle';
import { MESSAGE_NOT_NULL } from '@/constants/registerData';
import throttle from 'lodash/throttle';
import { setMinMaxSizeColumns, getWidthByTextContent } from '@/utils/calcTextWidth';
import {
  formatValue,
  $_helper_isNumberType,
  validateNumberField,
} from '@/concerns/newRegisterData/wijmo.helper';
import { getWidthOfAttachedButton } from '@/utils/multiLanguage';

export default {
  name: 'ListData',
  components: {
    DataTable,
    PageTitle,
    DialogPopup,
    NotificationPopup,
    CommonButton,
    EvidenceStoragePopup,
  },
  props: {},
  data() {
    return {
      items: [
        {
          text: this.$t('supplier_products.title_emission_data_submissions_form'),
          disabled: false,
          href: ROUTES.SUPPLIER,
        },
        {
          text: this.$t('supplier_products.title_emission_register'),
          disabled: true,
          href: ROUTES.REGISTER_PRIMARY_PRODUCT_DATA,
        },
      ],
      flexGrid: null,
      supplierGrid: null,
      supplierList: [],
      gridColumns: [],
      isEmptySource: true,
      dialogPopup: false,
      alertNotify: false,
      evidences: {},
      isChangeEvidence: false,
      openDialogEvidenceStorage: false,
      typeFormEvidenceStorage: 'view',
      onlyViewEvidence: false,
      selectedRow: null,
      dialogDeleteDataApproval: false,
      messageDialogDeleteDataApproval: '',
      pattern: {},
      messageNotification: '',
      infoFormPrimaryData: {},
      dbMasterOriginal: [],
      itemsInView: ['name', 'activity_level', 'activity_level_unit'],
      isLoadingSubmit: false
    };
  },

  async mounted() {
    await Promise.all([this.updateBreadCrumb(this.items), this.defineDataTable()]);
  },
  computed: {
    ...mapState('commonApp', ['isExpand']),
    ...mapState('registerData', ['isFullScreen']),
    ...mapState('userData', ['currentUser', 'dbSourceId']),
    ...mapState('supplier', ['supplierData', 'dataInfo']),
    titlePage() {
      return '排出量登録';
    },
    messageLabelEmpty() {
      return this.$t('idea_db_source.description_source_details_empty');
    },
    descriptionScope() {
      return {
        onlyView: this.onlyViewEvidence,
      };
    },
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    ...mapActions('commonApp', ['updateBreadCrumb', 'actionUpdateIsLoadingTable']),
    ...mapActions('supplier', ['setStatusDataSupplier']),
    getSettingIcon(image) {
      if (image) {
        return require(`@/assets/icons/${image}`);
      }
      return '';
    },
    dateTimeFormat(dateTime) {
      return formatDateTime(dateTime);
    },
    async getInfoFormPrimaryDataApi() {
      await infoFormPrimaryDataApi(this.$route?.query?.primary_uuid)
        .then((res) => {
          this.infoFormPrimaryData = res.data;
        })
        .catch(() => {
          const ROLE_SUPLIER = 6;
          if (res.user.role_id === ROLE_SUPLIER) {
            this.$router.push('/404');
          } else {
            this.$router.push({ path: ROUTES.TYPE_SELECTION });
          }
        });
    },
    async getDetailForm() {
      this.actionUpdateIsLoadingTable(true);
      await getDetailUnitSupplier(this.$route?.query?.primary_uuid)
        .then((res) => {
          res.data.forEach((item) => {
            this.supplierList.push({
              template_unit_id: item.id,
              activity_level_unit: item.activity_level_unit,
              name: item.name,
              id: BLANK_ID,
            });
          });
        })
        .catch(() => {
          this.actionUpdateIsLoadingTable(false);
        });
    },
    async defineDataTable() {
      await this.getInfoFormPrimaryDataApi();
      await this.getDetailForm();
      this.supplierGrid = new CollectionView(this.supplierList, { trackChanges: true });
      this.defineTable();
      this.isEmptySource = false;
      this.actionUpdateIsLoadingTable(false);
    },
    initializeGrid(grid) {
      this.flexGrid = grid;

      if (this.flexGrid) {
        this.flexGrid.columnGroups = this.getHeaderTable;
      }

      document.addEventListener('keydown', (e) => {
        if (
          (e.metaKey || e.ctrlKey) &&
          [
            KEYS_CODE.DOWN_ARROW,
            KEYS_CODE.UP_ARROW,
            KEYS_CODE.LEFT_ARROW,
            KEYS_CODE.RIGHT_ARROW,
            KEYS_CODE.ENTER,
          ].includes(e.keyCode)
        ) {
          e.preventDefault();
        }
      });
      this.actionUpdateIsLoadingTable(false);
      grid.hostElement.addEventListener(
        'keydown',
        (e) => {
          if (e.metaKey || e.ctrlKey) {
            if (e.keyCode === KEYS_CODE.DOWN_ARROW) {
              const currentSelection = grid.selection;
              const cellRange = new wjGrid.CellRange(grid.rows.length - 1, currentSelection.col);
              grid.selection = cellRange;

              // re-select after add more
              setTimeout(() => {
                grid.selection = cellRange;
              }, 200);
            } else if (e.keyCode === KEYS_CODE.UP_ARROW) {
              const currentSelection = grid.selection;
              const cellRange = new wjGrid.CellRange(0, currentSelection.col);
              grid.selection = cellRange;
            } else if (e.keyCode === KEYS_CODE.RIGHT_ARROW) {
              const currentSelection = grid.selection;
              const cellRange = new wjGrid.CellRange(currentSelection.row, grid.columns.length - 1);
              grid.selection = cellRange;
            } else if (e.keyCode === KEYS_CODE.LEFT_ARROW) {
              const currentSelection = grid.selection;
              const cellRange = new wjGrid.CellRange(currentSelection.row, 2);
              grid.selection = cellRange;
            }
          }

          if (e.keyCode === KEYS_CODE.ENTER) {
            e.preventDefault();
            e.stopImmediatePropagation();
          }
          if (e.keyCode === KEYS_CODE.KEY_V) {
            this.actionUpdateIsLoadingTable(true);
            const { row, row2 } = grid.selection;
            const isSingleRowPasted = row2 === row;
            if (isSingleRowPasted) {
              setTimeout(() => {
                this.actionUpdateIsLoadingTable(false);
              }, 300);
            }
          }
        },
        false,
      );
      grid.formatItem.addHandler((s, e) => {
        const colBinding = e.panel.columns[e.col].binding;
        if (e.panel == s.cells && colBinding === 'activity_level') {
          const rowData = s.rows[e.row].dataItem;
          if (rowData) {
            const idServiceSource = rowData?.evidences;
            if (idServiceSource) {
              wjcCore.addClass(e.cell, 'auto-increment');
            }
          }
        }
      });
      grid.cellEditEnded.addHandler((s, e) => {
        let column = s.columns[e.col];
        const { row, col } = e.range;
        const cellData = s.getCellData(row, col, false);
        const view = s.collectionView;
        const source = view.sourceCollection;

        if (['activity_level'].includes(column.binding)) {
          const isNumber = $_helper_isNumberType(cellData);
          const range = 25;
          const subStringRang = cellData?.includes('-') ? range + 1 : range;
          const valueFormat = addThoundSandComma(formatValue(cellData)?.substring(0, subStringRang));
          s.setCellData(row, col, isNumber ? valueFormat : '', false, true);
        }
      });
      grid.beginningEdit.addHandler((s, e) => {
        let column = s.columns[e.col];
        if (column.binding === 'name' || column.binding === 'activity_level_unit') {
          e.cancel = true;
        }
      });
      grid.pasted.addHandler((s, e) => {
        const { col, col2, row, row2 } = e.range;
        for (let colIndex = col; colIndex <= col2; colIndex++) {
          for (let rowIndex = row; rowIndex <= row2; rowIndex++) {
            const cellData = s.getCellData(rowIndex, colIndex, false);
            const binding = s.columns[colIndex].binding;
            if (['activity_level'].includes(binding)) {
              const isNumber = $_helper_isNumberType(cellData);
              const range = 25;
              const subStringRang = cellData?.includes('-') ? range + 1 : range;
              const valueFormat = addThoundSandComma(formatValue(cellData)?.substring(0, subStringRang));
              s.setCellData(rowIndex, colIndex, isNumber ? valueFormat : '', false, true);
            }
          }
        }
        setTimeout(() => {
          this.actionUpdateIsLoadingTable(false);
        }, 0);
      });
      grid.pastingCell.addHandler((s, e) => {});
    },
    defineTable() {
      this.gridColumns = this.getGridColumns();
      this.flexGrid.columnGroups = this.getGridColumns();
      this.supplierGrid?.collectionChanged.addHandler(
        throttle(() => {
          this.supplierGrid.itemsAdded.forEach((itemAdded) => {
            if (
              this.itemsInView.every(
                (field) => itemAdded[field] === null || itemAdded[field] === '' || itemAdded[field] === undefined,
              )
            ) {
              return null;
            }
          });
        }),
      );
      this.supplierGrid.currentItem = null;
      this.isEmptySource = this.supplierGrid?.items?.length <= 0;
      setMinMaxSizeColumns(this.flexGrid, this.supplierList);
      this.$nextTick(() => {
        this.scrollToTop();
      });
    },
    getGridColumns() {
      const widthTooltip = 10;
      return [
        {
          header: "#",
          binding: "id",
          allowSorting: false,
          isReadOnly: true,
          visible: false
        },
        {
          header: this.$t('supplier_products.table_emissions_product_item_name'),
          binding: 'name',
          minWidth: getWidthByTextContent(this.$t('supplier_products.table_emissions_product_item_name'), widthTooltip),
          maxWidth: 400,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          wordWrap: true,
          align: 'left',
          isRequired: false,
          multiLine: true,
          wordWrap: true,
        },
        {
          header: this.$t('supplier_products.table_emissions_product_emissions_intensity'),
          align: 'center',
          columns: [
            {
              header: this.$t('supplier_products.table_emissions_product_number'),
              binding: 'activity_level',
              minWidth: getWidthByTextContent(this.$t('supplier_products.table_emissions_product_number'), widthTooltip),
              maxWidth: 400,
              allowSorting: false,
              isRequired: false,
              isReadOnly: false,
              wordWrap: true,
              align: 'right',
            },
            {
              header: this.$t('supplier_products.table_emissions_product_unit'),
              binding: 'activity_level_unit',
              minWidth: getWidthByTextContent(this.$t('supplier_products.table_emissions_product_unit'), widthTooltip),
              maxWidth: 400,
              allowSorting: false,
              isRequired: false,
              isReadOnly: true,
              wordWrap: true,
              align: 'left',
            },
          ],
        },
        {
          header: this.$t('56_pattern.table_attach_file'),
          binding: 'attach_file',
          cssClass: 'auto-increment',
          minWidth: getWidthOfAttachedButton(this.$i18n.locale),
          maxWidth: getWidthOfAttachedButton(this.$i18n.locale),
          allowSorting: false,
          isRequired: false,
          cssClassAll: 'btn-db attach-file hide-filter',
          cellTemplate: (ctx, el) => {
            let text = this.$t('56_pattern.table_attach_file');
            if (ctx.item?.evidence) {
              text = `<div class="file-attached-container">
                <img src="${require('@/assets/icons/file_attached.svg')}" alt="file attached icon" class="file_attached">
              </div>`;
            }
            const button = CellMaker.makeButton({
              text: text,
              click: (e, context) => this.handleOpenAttachFile(e, context),
            });
            return button(ctx, el);
          },
        },
      ];
    },
    handleOpenAttachFile(e, context) {
      this.evidences = {};
      this.selectedRow = context.row.index;

      let evidenceData = context?.item?.evidence;
      if (typeof evidenceData === 'string') {
        evidenceData = JSON.parse(evidenceData);
      }
      if (evidenceData && evidenceData.file_name) {
        this.evidences.data = evidenceData;
        this.typeFormEvidenceStorage = 'view';
      } else {
        this.typeFormEvidenceStorage = 'create';
      }
      this.openDialogEvidenceStorage = true;
    },
    handleSubmitAttachFile(dataEvidence) {
      let evidenceDataInCellTable = this.supplierGrid.sourceCollection[this.selectedRow]?.evidence;
      dataEvidence = {
        ...evidenceDataInCellTable,
        ...dataEvidence,
        created_at: evidenceDataInCellTable ? evidenceDataInCellTable.created_at : dataEvidence.created_at, // TODO: always get the first created date of the record
      };
      const rowIndex = this.selectedRow;
      this.updateFlexgridCells(rowIndex, {
        attach_file: dataEvidence?.file_name,
      });
      if (this.supplierGrid.sourceCollection[rowIndex] && dataEvidence?.file_name) {
        this.supplierGrid.sourceCollection[rowIndex].evidence = dataEvidence;
        this.supplierGrid.sourceCollection[rowIndex].attach_file = dataEvidence.file_name;
      }
    },
    handleDeleteAttachFile() {
      this.evidences = {};
      this.updateFlexgridCells(this.selectedRow, {
        attach_file: null,
      });
      this.supplierGrid.sourceCollection[this.selectedRow].evidence = null;
      this.supplierGrid.sourceCollection[this.selectedRow].attach_file = null;
    },
    updateFlexgridCells(rowIndex, cellData) {
      if (!this.supplierGrid.sourceCollection[rowIndex]) {
        // this.addBlankItemsToView(1);
      }
      for (const columnName in cellData) {
        const colIndex = getColumnIndexByBinding(this.flexGrid, columnName);
        if (colIndex >= 0) {
          this.flexGrid.setCellData(rowIndex, colIndex, cellData[columnName], false, true);
        }
      }
    },
    scrollToTop() {
      if (!this.flexGrid) {
        return;
      }
    },
    onChangeFullScreen(isFullScreen) {
      if (isFullScreen) {
        this.scrollToTop();
      } else {
        this.initialView();

        this.$nextTick(() => {
          this.scrollToTop();
        });
      }
    },
    blankData(clientRowId) {
      return {
        id: BLANK_ID,
        attach_file: null,
        name: null,
        activity_level: null,
        activity_level_unit: null,
      };
    },
    addBlankItemsToView(count) {
      const lastClientId = 0;
      for (let index = 1; index <= count; index++) {
        this.supplierGrid.addNew(this.blankData(lastClientId + index));
      }

      this.supplierGrid.commitNew();
      this.supplierGrid.clearChanges();
    },
    closePopup() {
      this.commentPopup = false;
      this.historyPopup = false;
    },
    onHandleActionTable() {
      this.supplierGrid.getError = this.getError;
      let errorData = [];
      this.supplierGrid.sourceCollection.forEach((item) => {
        if (this.itemsInView.every((field) => item[field] === null || item[field] === '')) {
          return true;
        }
        this.flexGrid.columns.forEach((column) => {
          errorData.push(this.getError(item, column.binding));
          return true;
        });
      });
      errorData = errorData.filter((item) => item !== null);
      if (errorData.length > 0) {
        if (errorData.includes(MESSAGE_NOT_NULL) || errorData.includes(this.$t('validation.error_number_field'))) {
          this.alertNotify = true;
          this.messageNotification = this.$t('supplier_products.popup_message_require_input');
        }
      } else {
        this.dialogPopup = true; // pass all case and can submit data
      }
    },
    prepareDataSubmit() {
      let editedBlank = {};
      let indexBlank = 0;
      let self = this;
      self.supplierGrid.sourceCollection.forEach((record, indexWithBlankId) => {
        const everyValueNotChange = this.itemsInView.every((field) => {
          return record[field] === null || record[field] === '' || record[field] === undefined;
        });
        if (!everyValueNotChange || record.evidence) {
          indexBlank += 1;
          const dataValuesMapping = {};
          dataValuesMapping.activity_level = formatValue(record.activity_level);
          dataValuesMapping.template_unit_id = record.template_unit_id;
          dataValuesMapping.index = indexBlank;
          let prepareData = {
            ...dataValuesMapping,
            evidence: record.evidence || null,
          };
          editedBlank[indexWithBlankId] = prepareData;
        }
      });
      return editedBlank;
    },
    submitDataSupplier() {
      this.isLoadingSubmit = true;
      if (!this.infoFormPrimaryData.uuid) {
        this.$router.push({
          path: ROUTES.SUPPLIER,
          query: { primary_uuid: this.$route?.query?.primary_uuid },
        });
        this.isLoadingSubmit = false;
      } else {
        const data = this.prepareDataSubmit();
        const dataSubmit = {
          action: 'submit',
          data: Object.values(data),
          dataWorkflow: {
            uuid_primary_data: this.infoFormPrimaryData.uuid,
            note: this.dataInfo.note,
          },
        };
        if(Object.values(data).length) {
          submitDataSupplierCFPAction(dataSubmit)
            .then((res) => {
              this.dialogPopup = false;
              this.setStatusDataSupplier(true);
              this.actionUpdateIsLoadingTable(false);
              this.$router.push({
                path: ROUTES.SUPPLIER,
                query: { primary_uuid: this.$route?.query?.primary_uuid },
              });
            })
            .catch((error) => {
              if (!error.errors?.data_duplicate && getErrorMessge(error).length > 0) {
                this.isReloadPage = false;
                this.dialogDeleteDataApproval = true;
                this.messageDialogDeleteDataApproval = getErrorMessge(error);
              }
              this.dialogPopup = false;
              this.actionUpdateIsLoadingTable(false);
            }).finally(() => {
              this.isLoadingSubmit = false;
            })
        } else {
          emptyPromise();
          this.isLoadingSubmit = false;
        }

      }
    },
    getError(item, propName) {
      if (this.itemsInView.every((field) => item[field] === null || item[field] === '' || item[field] === undefined)) {
        return null;
      }
      switch (propName) {
        case 'activity_level':
          if (item === undefined || item === null || item === '') {
            return this.$t('validation.error_required_field');
          } else {
            return validateNumberField(String(item[propName]), propName);
          }
      }
      return null;
    },
    emptyCell(item) {
      if (item === undefined || item === null || item === '') {
        return this.$t('validation.error_required_field');
      }
      return null;
    },
  },
};
</script>
<style scoped lang="scss">
.list-data-header {
  display: flex;
  justify-content: space-between;
  align-items: start;
  @media (max-width: $desktop) { 
    align-items: center;
  }
}
.submit-register-btn {
  min-width: 75px !important;
}
@media (max-width: 480px) {
  .custom-table {
    padding-left: 20px !important;
  }
  .category-table {
    margin-right: -20px !important;
  }
}
@media (min-width: 480px) {
  .wrap {
    .table-supplier {
      width: fit-content;
      max-width: 100%;
    }
  }
  .custom-table {
    padding-right: 0 !important;
  }
}
.list-menu--title {
  color: $monoBlack;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.75px;
  padding-bottom: 48px;
  margin-bottom: 0px;
}
.new-register-supplier {
  display: flex;
  align-items: center;
  justify-content: center;
}
.table-supplier {
  ::v-deep .wj-flexgrid .wj-cell.hide-filter .wj-btn-glyph {
    display: none;
  }
}
</style>
